<template>
    <div class="row">
        <div class="col-md-4">
            <div class="input-group " :title="$t('general.Base imponible')">
                <div class="input-group-prepend" >
                    <span class="input-group-text" style="min-width:120px" >{{ $t('general.total') + ' ' + $t('general.a cliente') }}</span>
                </div>
                <input style="text-align: end" type="text" class="form-control" :value="ttales.cliente_base + '&euro;'" readonly >
                <input style="text-align: end" type="text" class="form-control" :value="facturarcliente + '&euro;'" readonly :title="$t('general.seleccionado') + ' ' + $t('general.para facturar')">
                <div class="input-group-append">
                    <button type="button" class="btn btn-info" @click="mostrarTotalesAmpliados">
                        <i class="fas fa-caret-down"></i>
                    </button>
                </div>
            </div>
            <div class="input-group totales_ampliado">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="min-width:120px">IVA 21%</span>
                </div>
                <input style="text-align: end" type="text" class="form-control" :value="ttales.cliente_iva + '&euro;'" readonly>
                <input style="text-align: end" type="text" class="form-control" :value="facturariva + '&euro;'"  readonly :title="$t('general.seleccionado') + ' ' + $t('general.para facturar')">
            </div>
            <div class="input-group totales_ampliado">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="min-width:120px">{{ $t('general.total') }}</span>
                </div>
                <input style="text-align: end" type="text" class="form-control" :value="ttalesiva + '&euro;'" readonly>
                <input style="text-align: end" type="text" class="form-control" :value="facturartotal + '&euro;'" readonly :title="$t('general.seleccionado') + ' ' + $t('general.para facturar')">
            </div>
            <div class="input-group totales_ampliado">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="min-width:120px"></span>
                </div>
                <input type="text" class="form-control" value="Total Expediente" readonly>
                <input type="text" class="form-control" readonly :value="$t('general.para facturar')">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group " :title="$t('general.Base imponible')">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{ $t('general.total') + ' ' + $t('general.coste') }}</span>
                </div>
                <input style="text-align: end" type="text" :value="ttales.operario + '&euro;'" class="form-control" readonly>
            </div>
        </div>
        
        <div class="col-md-3">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{$t('general.rentabilidad')}}</span>
                </div>
                <input style="text-align: end" type="text" class="form-control" v-model="result" readonly>                
            </div>
        </div>
        <div class="col-md-2" v-if="(ttales.rentabilidad_cia != false) || ttales.rentabilidad_cia.length >1">
            <div class="input-group-prepend">
                <input title="El expediente está por debajo de la rentabilidad configurada" type="text" style="background:#dc3545;color:white;font-weight:bold;text-align:center" class="form-control" value="No rentable" readonly>                
            </div>      
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
//import { PwgsApi } from '../../../../services/PwgsApi';
export default ({
    props:[
        'id',
        'servicio',
        'recargar',
        'totalcliente',
        'totaloperario',
        'totalrent', 
        'operarios',
        'actualizartotales'
    ],
    setup() {
        return;
    },
     data() {
        return {
            totalop:'',
            totalcli: '',
            rent: '',
            iva: '',
            totalescliente: '',
            totalesrentabilidad: '',
            totalesoperario: '',
            localOperarios: {},
            total: '',
            trabajodatos: '',
            preciototal: '',
            facturarcliente: '',
            facturaroperario: '',
            facturarrentabilidad: '0%',
            facturariva: '0.00',
            facturartotal: '0.00',
            ttales: {},
            ttalesiva: 0,
            result: '0%',
            rentab:true,
        };
    },
    methods: {
        mostrarTotalesAmpliados(){
            $(".totales_ampliado").toggle();
        },
        cargarmasdatos() {

            if (!this.operarios || Object.keys(this.operarios).length === 0) {
                // Si 'operarios' no está disponible, no podemos continuar
                return;
            }

            // Hacer una copia profunda de 'operarios'
            this.localOperarios = JSON.parse(JSON.stringify(this.operarios));

            // Verificar si 'totales' existe en 'localOperarios'
            if (!this.localOperarios.totales) {
                // Si 'totales' no está disponible, no podemos continuar
                return;
            }

            // Ahora, utilizar 'localOperarios' para los cálculos
            this.ttales = this.localOperarios.totales;
            var ttliva = +this.ttales.cliente_base + +this.ttales.cliente_iva;
            this.ttalesiva = Math.round(ttliva * 100) / 100;
            this.facturarcliente = '';
            this.facturariva = '';
            this.facturaroperario = '';
            console.log('rentab', this.ttales);
            if (this.ttales.rentabilidad_cia) {
                if (this.ttales.beneficio.porcentaje < this.ttales.rentabilidad_cia)
                    this.rentab = false;
            }
            console.log('localopers', this.localOperarios);
            for (let oper in this.localOperarios.operario) {
                const operarioData = this.localOperarios.operario[oper];
                console.log('opersdata', operarioData);
                Object.entries(operarioData).forEach(([tipo_cliente, trabajos_tipo_cliente]) => {

                    if(tipo_cliente!=='operario' && tipo_cliente!=='totales'){
                        
                        for (let i = 0; i < trabajos_tipo_cliente.trabajos.length; i++) {
                            if (trabajos_tipo_cliente.trabajos[i].datos.facturar_trabajo === "SI") {
                                
                                this.trabajodatos = trabajos_tipo_cliente.trabajos[i].datos;
                                console.log('trabajodatos12', this.trabajodatos);
                                let subtotal = this.trabajodatos.precio * this.trabajodatos.cantidad;
                                console.log('llega', subtotal);
                                // Si el descuento es en importe, calcula el porcentaje de descuento
                                if (this.trabajodatos.descuento_importe != 0) {
                                    console.log('entra1', subtotal);
                                    this.trabajodatos.descuento = (this.trabajodatos.descuento_importe / subtotal) * 100;
                                    this.trabajodatos.descuento = Math.round(this.trabajodatos.descuento * 100) / 100;
                                }  
                                // Si el descuento es en porcentaje, calcula el precio total con descuento
                                if (this.trabajodatos.descuento != 0) {
                                    console.log('entra2', subtotal);
                                    let descuento = subtotal * (this.trabajodatos.descuento / 100);
                                    if (this.trabajodatos.desc_incr != 0) {
                                        this.preciototal = subtotal + descuento;
                                    }else{
                                        this.preciototal = subtotal - descuento;
                                    }
                                } else {
                                    this.preciototal = subtotal;
                                    console.log('entra', this.preciototal);
                                }
                                // Redondea el precio total a 2 decimales
                                this.preciototal = Math.round(this.preciototal * 100) / 100;
                                this.facturarcliente = +this.facturarcliente + +this.preciototal;
                            }
                        }
                    }
                });
            }
            for (let oper in this.localOperarios.proveedor) {
                const operarioData = this.localOperarios.proveedor[oper];
                console.log('opersdata', operarioData);
                Object.entries(operarioData).forEach(([tipo_cliente, trabajos_tipo_cliente]) => {

                    if(tipo_cliente!=='operario' && tipo_cliente!=='totales'){
                        
                        for (let i = 0; i < trabajos_tipo_cliente.trabajos.length; i++) {
                            if (trabajos_tipo_cliente.trabajos[i].datos.facturar_trabajo === "SI") {
                                
                                this.trabajodatos = trabajos_tipo_cliente.trabajos[i].datos;
                                console.log('trabajodatos12', this.trabajodatos);
                                let subtotal = this.trabajodatos.precio * this.trabajodatos.cantidad;
                                console.log('llega', subtotal);
                                // Si el descuento es en importe, calcula el porcentaje de descuento
                                if (this.trabajodatos.descuento_importe != 0) {
                                    console.log('entra1', subtotal);
                                    this.trabajodatos.descuento = (this.trabajodatos.descuento_importe / subtotal) * 100;
                                    this.trabajodatos.descuento = Math.round(this.trabajodatos.descuento * 100) / 100;
                                }  
                                // Si el descuento es en porcentaje, calcula el precio total con descuento
                                if (this.trabajodatos.descuento != 0) {
                                    console.log('entra2', subtotal);
                                    let descuento = subtotal * (this.trabajodatos.descuento / 100);
                                    if (this.trabajodatos.desc_incr != 0) {
                                        this.preciototal = subtotal + descuento;
                                    }else{
                                        this.preciototal = subtotal - descuento;
                                    }
                                } else {
                                    this.preciototal = subtotal;
                                    console.log('entra', this.preciototal);
                                }
                                // Redondea el precio total a 2 decimales
                                this.preciototal = Math.round(this.preciototal * 100) / 100;
                                this.facturarcliente = +this.facturarcliente + +this.preciototal;
                            }
                        }
                    }
                });
            }
            // Realizar los cálculos restantes
            this.facturarrentabilidad = Math.round(((this.facturarcliente - this.facturaroperario) * 100).toFixed(2) / this.facturarcliente) + "%";
            this.facturariva = ((+this.facturarcliente) * 0.21).toFixed(2);
            this.facturartotal = (+this.facturarcliente + +this.facturariva).toFixed(2);
            this.result = this.ttales.beneficio.porcentaje + '%';
        },
    },
    mounted (){
        $(".totales_ampliado").hide();
        //this.cargarmasdatos();
    },
    watch: {
        actualizartotales(){
            this.cargarmasdatos();
        },
        id() { 
            $(".totales_ampliado").hide();
            //this.cargarmasdatos();
        },
        recargar() {
            this.cargarmasdatos();
        },
        operarios(newVal) {
            if (newVal && Object.keys(newVal).length > 0) {
                this.cargarmasdatos();
            }
        },
    }
})
</script>
